<template>
  <div class="" v-if="withholding">
    <el-form :model="formDocument" :rules="rules" ref="formDocument">
      <TopBarTitleComponent title="Editar Retención" :showBackIcon="true">
        <template slot="right-area">
          <div class="d-flex justify-content-end align-items-center">
            <ButtonPrimaryComponent
              title="Guardar"
              @onSaveClick="handleSaveClick"
            />
          </div>
        </template>
      </TopBarTitleComponent>
      <b-container fluid class="pb-4 px-lg-4 pt-4">
        <b-row>
          <b-col>
            <div class="card box-shadow">
              <div class="card-body">
                <b-row>
                  <b-col xs="12" sm="12" md="12">
                    <b-row>
                      <b-col xs="12" sm="12" md="3">
                        <el-form-item prop="user_business_client_id">
                          <SelectBusinessComponent
                            :setUserBusinessClientId="
                              formDocument.user_business_client_id
                            "
                            @onChangeSelect="onChangeSelectBusiness"
                          />
                        </el-form-item>
                      </b-col>
                      <b-col xs="12" sm="12" md="3">
                        <InputDatePickerComponent
                          @onChangeDatePicker="onChangeDatePicker"
                          prop="emission_date"
                          placeholder=""
                          title="Fecha de emisión"
                          :isDisabledDate="true"
                          :setDate="formDocument.emission_date"
                        />
                      </b-col>
                      <b-col xs="12" sm="12" md="3">
                        <el-form-item prop="user_business_e_point_id">
                          <SelectEmissionPointsComponent
                            :setEmissionPointId="
                              formDocument.user_business_e_point_id
                            "
                            @onChangeSelect="onChangeSelectEmissionPoints"
                          />
                        </el-form-item>
                      </b-col>
                      <b-col xs="12" sm="12" md="3">
                        <el-form-item prop="fiscal_year">
                          <slot name="label">
                            <label class="font-semi-bold m-0"
                              >Período fiscal</label
                            >
                          </slot>
                          <div class="d-flex">
                            <el-select v-model="formDocument.fiscal_month">
                              <el-option
                                :value="month.value"
                                :label="month.label"
                                :key="index"
                                v-for="(month, index) in months"
                              ></el-option>
                            </el-select>
                            <el-input
                              type="text"
                              placeholder=""
                              class="ml-4"
                              v-model.number="formDocument.fiscal_year"
                            ></el-input>
                          </div>
                        </el-form-item>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <WithholdingTableTaxes
                  :setTaxes="formDocument.details"
                  @onToogleTaxes="onToogleTaxes"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </el-form>
  </div>
</template>

<script>
import ButtonPrimaryComponent from "../../../../components/ButtonPrimary";
import TopBarTitleComponent from "../../../../components/TopBarTitle";
import InputDatePickerComponent from "../../../../components/InputDatePicker";
import SelectBusinessComponent from "../../../../components/SelectBusiness";
import SelectEmissionPointsComponent from "../../../../components/SelectEmissionPoints";
import WithholdingTableTaxes from "../components/WithholdingTableTaxes";

import withholdingService from "../services/withholdingService";
import Validate from "@/assets/validate";
import {
  months,
  DATE_AT,
  YEAR_DATE_AT,
  MONTH_DATE_AT
} from "@/services/DateServices";

export default {
  name: "WithholdingEditPage",
  data: () => ({
    withholding: null,
    months,
    formDocument: {
      user_business_client_id: "",
      user_business_e_point_id: "",
      emission_date: DATE_AT,
      fiscal_month: MONTH_DATE_AT,
      fiscal_year: YEAR_DATE_AT,
      details: []
    },
    rules: {
      user_business_client_id: [Validate.rules.required("change")],
      user_business_e_point_id: [Validate.rules.required("change")],
      emission_date: [Validate.rules.required()],
      fiscal_month: [Validate.rules.required()],
      fiscal_year: [Validate.rules.numeric()]
    }
  }),
  computed: {},
  methods: {
    async getWithholding(withholdingId) {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await withholdingService
          .getWithholdingId(withholdingId)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, data } = response.data;
        if (success) {
          this.withholding = data;
          this.formDocument = {
            ...this.formDocument,
            ...this.withholding
          };
        }
      } catch (error) {
        return false;
      }
    },
    handleSaveClick() {
      this.$refs.formDocument.validate(async valid => {
        if (valid) {
          if (!this.formDocument.details.length) {
            this.$notifications.warning({
              message: "Debes seleccionar al menos un inpuesto de retención."
            });
            return false;
          }
          this.$store.dispatch("toggleRequestLoading");
          try {
            let response = await withholdingService
              .updateWithholdingId(this.withholding.id, this.formDocument)
              .finally(() => {
                this.$store.dispatch("toggleRequestLoading");
              });
            const { success, message } = response.data;
            if (success) {
              this.$notifications.success({
                message
              });
            }
          } catch (error) {
            return false;
          }
        }
      });
    },
    goRouter(name, params = {}) {
      this.$router.push({ name, params });
    },
    onChangeDatePicker({ date }) {
      this.formDocument.emission_date = date;
    },
    onChangeSelectBusiness({ client }) {
      const { id } = client;
      this.formDocument.user_business_client_id = id;
    },
    onChangeSelectEmissionPoints({ emissionPointId }) {
      this.formDocument.user_business_e_point_id = emissionPointId;
    },
    onToogleTaxes({ taxes }) {
      this.formDocument.details = taxes;
    }
  },
  components: {
    ButtonPrimaryComponent,
    TopBarTitleComponent,
    InputDatePickerComponent,
    SelectBusinessComponent,
    SelectEmissionPointsComponent,
    WithholdingTableTaxes
  },
  mounted() {
    const { id: withholdingId } = this.$route.params;
    if (withholdingId) this.getWithholding(withholdingId);
  }
};
</script>

<style lang="sass" scoped></style>
